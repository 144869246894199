// import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";

import {
  AnalyticsIcon,
  ManIcon,
  FuelIcon,
  DocIcon,
  ServicesIcon,
  PetroIcon,
  // FlyingIcon,
  // PilotIcon,
  TaIcon,
} from "assets/icons";
import { ExclusiveServiceRoot } from "styles/styles";

const ExclusiveServiceScreen = ({ onNextStep }) => {
  // const [{ loading, error }, submitData] = useSubmitForm({
  //   method: "updateLead",
  //   path: "/lead",
  //   callback: onNextStep,
  // });
  // const handleSubmit = () => {
  //   submitData({ customerId: customer.customerId, step: "Exclusive service" });
  // };

  return (
    <ExclusiveServiceRoot>
      <h2 className="title" style={{ marginBottom: "4rem" }}>
        Exclusive service only for owner operators
      </h2>

      <div className="card">
        <h3 className="card-title">
          <AnalyticsIcon /> Instant payments on every load
        </h3>
        <p className="card-desc">
          Do not wait for the payment for one week, get paid after every load.
          Send the scan copy of the load that you just delivered and get paid
          the same day.
        </p>
      </div>

      <div className="card">
        <h3 className="card-title">
          <ManIcon /> Professional dispatcher
        </h3>
        <ul className="list">
          <li className="list-item">5+ years experience Dispatch</li>
          <li className="list-item">broker accounts</li>
          <li className="list-item">regular freight</li>
          <li className="list-item">facility appointment check</li>
          <li className="list-item">
            90% Detentions and Layover Compensation Rate
          </li>
        </ul>
      </div>

      <div className="card">
        <h3 className="card-title">
          <FuelIcon /> Fuel discounts
        </h3>

        <div className="images">
          <PetroIcon />
          {/* <PilotIcon /> */}
          <TaIcon />
          {/* <FlyingIcon /> */}
        </div>
      </div>

      <div className="card">
        <h3 className="card-title">
          <DocIcon /> Full Transparency
        </h3>
        <p className="card-desc">
          Sending the rate confirmation from the broker to your email
        </p>
      </div>

      <div className="card">
        <h3 className="card-title">
          <ServicesIcon /> 24/7 services
        </h3>
        <ul className="list">
          <li className="list-item">Booking Team</li>
          <li className="list-item">Breakdown assistance</li>
          <li className="list-item">Driver Care Department</li>
          <li className="list-item">Safety</li>
          <li className="list-item">HOS</li>
        </ul>
      </div>

      <SendButton
        id="step-6"
        // error={error}
        // disabled={loading}
        onClick={onNextStep}
        // conditionalClasses={loading ? "inactive" : ""}
      >
        Continue
      </SendButton>
    </ExclusiveServiceRoot>
  );
};

export default ExclusiveServiceScreen;
