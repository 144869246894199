class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.ERROR_MESSAGE = "Something went wrong. Please try again";
  }

  async createLead(endpoint, values) {
    window.location.search
      .replace("?", "")
      .split("&")
      .map((str) => {
        if (str) {
          const elem = str.split("=");
          if (elem) values[elem[0]] = elem[1].split("%20").join(" ");
        }
      });

    const res = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        customerId: "",
      },
      body: JSON.stringify(values),
    });

    if (!res.ok) throw this.ERROR_MESSAGE;

    const { customerId } = await res.json();
    const { fullName, phone } = values;
    return { fullName: fullName || "", phone: phone || "", customerId };
  }

  async updateLead(endpoint, values) {
    const { customerId, ...restValues } = values;

    const res = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        customerId: customerId,
      },
      body: JSON.stringify(restValues),
    });

    if (!res.ok) throw this.ERROR_MESSAGE;
    return await res.json();
  }

  async sendPhoto(endpoint, values) {
    const { customerId, photo } = values;
    const formData = new FormData();
    formData.append("photo", photo);

    const res = await fetch(`${this.baseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        customerId,
      },
      body: formData,
    });
    if (!res.ok) {
      throw this.ERROR_MESSAGE;
    }
    return res.status;
  }
}

// export default new Api("https://apidispatch.trucking42.com");
export default new Api("");
// export default new Api("http://192.168.1.53:3005");
