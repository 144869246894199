import { useRef, useState } from "react";
import { useSubmitForm } from "hooks/useSubmitform";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import SendButton from "components/SendButton";
import { InterviewScreenRoot } from "styles/styles";

const InterviewScreen = ({ onNextStep, customer }) => {
  const [chosenDate, setChosenDate] = useState(false);
  // const [calandlyHeight, setCalandlyHeight] = useState(0);
  const uriRef = useRef("");
  const [{ loading, error }, submitData] = useSubmitForm({
    method: "updateLead",
    path: "/form/5",
    callback: () => onNextStep(),
  });

  const handleSubmit = () => {
    submitData({
      customerId: customer.customerId,
      step: "consult date",
      uri: uriRef.current,
    });
  };

  useCalendlyEventListener({
    onProfilePageViewed: () => {},
    onDateAndTimeSelected: () => {},
    onEventTypeViewed: () => {},
    onEventScheduled: (e) => {
      uriRef.current = `${e.data.payload.event.uri}`;
      setChosenDate(90);
    },
  });

  return (
    <InterviewScreenRoot>
      <h2 className="title">Interview</h2>
      <p className="description">
        Please choose your best time and date when one our driver recruiter can
        contact you
      </p>

      <InlineWidget
        url={`https://calendly.com/trucking42/30-minute-meeting?back=1&month&hide_gdpr_banner=1&name=${customer.fullName}&email=${customer.email}&phone=${customer.phone}&location=${customer.phone}`}
        pageSettings={{
          backgroundColor: "#fff",
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: "#C93030",
          textColor: "#0F1011",
          hideGdprBanner: true,
        }}
        styles={{
          marginTop: "35px",
          marginBottom: `${chosenDate}px`,
        }}
      />

      {chosenDate && (
        <div
          style={{
            position: "fixed",
            bottom: "2rem",
            left: "1.6rem",
            right: "1.6rem",
            textAlign: "center",
          }}
        >
          <SendButton
            id="step-7"
            error={error}
            disabled={loading}
            onClick={handleSubmit}
            style={{
              width: "100%",
              maxWidth: "343px",
            }}
            conditionalClasses={loading ? "inactive" : ""}
          >
            Next
          </SendButton>
        </div>
      )}
    </InterviewScreenRoot>
  );
};

export default InterviewScreen;
