// import { useState } from "react";
// import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";
import VideoPlayer from "components/VideoPlayer";

const GetSolutionsScreen = ({ onNextStep, customer }) => {
  // const [{ loading, error }, submitData] = useSubmitForm({
  //   method: "updateLead",
  //   path: "/form/5",
  //   callback: onNextStep,
  // });
  // const [watchComplete, setWatchComplete] = useState(false);
  // const inactiveButton = loading || !watchComplete;
  // const inactiveButton = !watchComplete;
  // const handleWatchComplete = ({ played }) => {
  //   if (played >= 0.9) setWatchComplete(true);
  // };
  // const handleSubmit = () => {
  //   submitData({ customerId: customer.customerId, step: "get solutions" });
  // };

  return (
    <div>
      <h2 className="title">Get solutions</h2>
      <p className="description">Watch this video till the end and get</p>
      <p className="subtitle" style={{ marginBottom: "2.8rem" }}>
        consultation with owner-operator
      </p>

      <div style={{ marginBottom: "4.8rem" }}>
        <VideoPlayer
          controls
          playsinline
          // onProgress={handleWatchComplete}
          // url="https://vimeo.com/804372427"
          url="https://vimeo.com/848336265"
          config={{
            vimeo: {
              playerOptions: { playsinline: true },
            },
          }}
        />
      </div>

      <SendButton
        id="step-5"
        // error={error}
        // disabled={inactiveButton}
        onClick={onNextStep}
        // conditionalClasses={inactiveButton ? "inactive" : ""}
      >
        Continue
      </SendButton>
    </div>
  );
};

export default GetSolutionsScreen;
